import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SocialConnect from "../components/ConnectSocial";

const NotFounc = ({ data }) => (
  <Layout title={"404"}>
    <div className="about">
      <div className="hero2">
        <h1>404 - Page not Found</h1>
      </div>
      <div className="ssbody wrapper_max">
        <div className="content" style={{ textAlign: "center" }}></div>
      </div>
    </div>
  </Layout>
);

export default NotFounc;
